import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './../RequestsItem/RequestsItem.scss';
import moment from 'moment';
import {getMyRequestsStatus, getStatusColor} from "../../../../helpers/functions";


class RequestsItem extends Component {

    render() {
        const {item} = this.props;

        return (
            <Link to={`/admin/refund-request/${item.id}`} className="table_row">
                <div className="row_orders">
                    <div className="row_item">
                        <div>{item.name}</div>
                    </div>
                    <div className="row_item">
                        <div>{item.article}</div>
                    </div>
                    <div className="row_item">
                        <div>{item.order}</div>
                    </div>
                    <div className="row_item">
                        <div>{item.producer}</div>
                    </div>
                    <div className="row_item">
                        <div>{moment(item.date).format('DD.MM.YYYY HH:mm')}</div>
                    </div>
                    <div className="row_item">
                        <div className="block_status">
                            <span
                                className="status_orders"
                                style={{ background: `${getStatusColor(item.status)}` }}
                            />
                            {getMyRequestsStatus(item.status)}
                        </div>
                    </div>
                    <div className="row_item">
                        <div>{item.amount}</div>
                    </div>
                    <div className="row_item">
                        <div>{item.price} <span className="ru_value">₽</span></div>
                    </div>
                </div>
            </Link>

        );
    }

}


export default RequestsItem;